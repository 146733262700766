@import "bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.topImage {
  object-fit: cover;
  object-position: "50% 50%";
}

.infoBg {
  background: #d2ece1;
}

.primaryBg {
  background: linear-gradient(
      0deg,
      rgba(255, 199, 117, 0.3) 0%,
      rgba(255, 199, 117, 0.3) 100%
    ),
    #fff;
}

.cobaltBg {
  background: #b9cee7;
}

.topImageContainer {
  height: 250px;
  overflow: hidden;
}

@include media-breakpoint-up(md) {
  .topImageContainer {
    font-size: 20px;
  }

  .topImage {
    object-fit: cover;
    object-position: 50% 50%;
  }

  .imageOverlay {
    background-image: linear-gradient(
      180deg,
      rgba(12, 65, 111, 0.5) 0%,
      rgba(12, 65, 111, 0) 50%
    );
  }

  .navbarTitle {
    font-size: 2rem;
    letter-spacing: -0.5;
  }
}
