// Adapted from https://codepen.io/designcouch/pen/ExvwPY

.hamburger-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    width: 100%;
    position: absolute;
    display: block;
    height: 20%;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 9px;
    position: absolute;
    height: calc(33% / 2);
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    z-index: 4;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 40%;
  }

  span:nth-child(3) {
    top: 40%;
  }

  span:nth-child(4) {
    bottom: 0;
  }

  &.dark {
    span {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &.active {
    span {
      background-color: rgba(255, 255, 255);
    }
    span:nth-child(1) {
      top: 40%;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 40%;
      width: 0%;
      left: 50%;
    }
  }
}
